import React, { useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useJQuery } from 'src/hooks';

const useStyles = makeStyles({
  root: {
    marginTop: 5
  },
  category: {
    fontSize: 18
  },
  title: {
    fontWeight: 700
  }
});

/** Accordion to handle FAQ articles. */
export default function CustomAccordion({ id }: { id: string }) {
  const classes = useStyles();
  const { jAction } = useJQuery();
  const [articles, setArticles] = useState<{ title: string, description: string, content: HTMLElement, id: string }[]>([]);

  useEffect(() => {
    console.log(`Searching for articles for the id: #${id}`);
    const articlesFound = $(`#${id}`).find('.articles').map((i, e) => ({
      id: $(e).find('.article-id').val() as string,
      title: $(e).find('.article-title').val() as string,
      description: $(e).find('.article-description').val() as string,
      content: $(e).find('.article-content')[0]
    })).toArray().filter(a => !!a.id);
    setArticles(articlesFound);
  }, []);

  useEffect(() => {
    console.log(`# of articles: ${articles.length} for id: ${id}`);
    if (articles.length) {
      articles.forEach(a => {
        $(a.content).css('display', 'initial');
        $(`#${id}`).find(`#content-${a.id}`).append(a.content);
      });
    }
  }, [articles])

  return (
    <div className={classes.root}>
      {articles.map(a =>
        <Accordion key={a.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="subtitle1" className={classes.title}>{a.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" id={`content-${a.id}`}>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

import { IUser } from '../reducers/auth';

export const SetUser = (user: IUser) => ({
  user,
  type: 'SET_USER'
})

export const SetNavigation = (path: string, open: boolean) => ({
  path,
  open,
  type: 'SET_NAV'
})

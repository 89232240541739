import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/global.scss'
import './assets/scss/material.scss'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import { JQueryProvider } from './hooks';

const store = createStore(rootReducer);

const theme = createTheme({
  palette: {
    primary: {
      main: '#006184',
      light: '#01b6d1',
      dark: '#003057'
    }
  },
  typography: {
    fontFamily: 'Zoram,sans-serif'
  }
});
// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',

const render = Component => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <JQueryProvider>
            <Component />
          </JQueryProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

if ((module as any).hot) {
  // (module as any).hot.accept();
  (module as any).hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

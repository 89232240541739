import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import flatMapDeep from 'lodash/flatMapDeep';
import last from 'lodash/last';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import NavListItem from './NavListItem';
import { SetUser } from 'src/actions';
import { useJQuery } from 'src/hooks';

import { CircleNotStarted, CircleStarted, CircleSelectedFilled, Minus, Profile, World, GearOutline, Language } from '@churchofjesuschrist/eden-icons';
import { MenuButton, WorkforceHeader } from '@churchofjesuschrist/eden-workforce-header';
import { Unstyled, Item } from '@churchofjesuschrist/eden-list';
import { A11y, Secondary, Icon } from '@churchofjesuschrist/eden-buttons';
import Callout from '@churchofjesuschrist/eden-callout';
import { H4 } from '@churchofjesuschrist/eden-headings';
import Row from '@churchofjesuschrist/eden-row';

const drawerWidth = 340;
const drawerHeight = 34;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 0
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: 99,
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerClosed: {
        width: 20
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        minHeight: `${drawerHeight}px !important`,
        '& > button': {
            padding: 8,
            height: drawerHeight
        }
    },
    drawerSubheader: {
        padding: theme.spacing(1, 2)
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        // marginLeft: drawerWidth
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    checkMark: {
        color: 'green'
    },
    empty: {
        color: 'transparent'
    },
    title: {
        flexGrow: 1,
        cursor: 'pointer',
        color: 'gray'
    },
    account: {
        color: '#232222',
        display: 'flex',
        alignItems: 'center'
    },
    hidden: {
        display: 'none'
    },
    wordMark: {
        height: 35,
        textDecoration: 'none',
        // borderBottom: '4px solid',
        borderImage: 'linear-gradient(0deg,var(--grey5),#fff) 0 0 100% 0',
        zIndex: 99,
        userSelect: 'none',
        color: 'var(--text120, #212225)',
        background: 'var(--white, white)',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    wordMarkShift: {
        marginLeft: -drawerWidth / 2 // half total width to center
    },
    slimLogo: {
        display: 'flex',
        margin: 9,
        justifyContent: 'center',
        alignItems: 'center',
        height: 14,
        fontSize: 'var(--headerFontSize13, 13)',
        lineHeight: 'var(--lineHeightTight, 1.2)',
        fontFamily: 'var(--serif, "Ensign:Serif", "Georgia", "Times New Roman", serif)',
        color: 'var(--grey40, #53575b)',
        textAlign: 'center'
    },
    unstyledList: {
        padding: 0,
        width: 200
    },
    workforceHeaderThemed: {
        '&#wf-header': {
            backgroundColor: 'var(--blue40, #003057)'
        }
    },
    menuItem: {
        padding: '.15rem 0',
        '& > hr': {
            marginTop: '.5rem',
            marginBottom: '.25rem',
            borderColor: 'var(--gray10, #d0d3d3)'
        },
        '& > div > .menuHeader': {
            fontSize: 'var(--headerFontSize14, 1rem)'
        },
        '& > button.language-item': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            '& > span.language-selected-ind': {
                color: 'var(--info40, #157493)'
            }
        }
    },
    headerIcon: {
        '&[type="button"]': {
            color: '#fff',
        },
        '&[type="button"]:hover': {
            color: 'initial'
        }
    },
    spacer: {
        background: '#bdc0c0',
        height: 28,
        width: 1,
        marginInlineStart: 4,
        marginInlineEnd: 4
    }
}));

/* eslint-disable no-unused-vars */
export const enum NavigationStatus {
    NotStarted,
    Started,
    Complete
}

interface INavigation {
    name: string;
    url: string;
    openNew: boolean;
    status: NavigationStatus;
    children?: INavigation[];
    statusHidden: boolean;
}

interface ILanguage {
    name: string;
    url: string;
    code: string;
    selected: boolean;
}

const iconSize = '1.5rem';

const specialCharRegex = /[\(\)\'\/\\*!@#]/g;

const getPageStatus = (url: string, children: INavigation[], statuses: { [index: string]: { status: NavigationStatus } } = {}) => {
    const shortUrl = last(url.split('/').filter(s => !!s)) as string;
    let status = statuses[shortUrl]?.status;
    if (children?.length > 0) {
        const notStartedCount = children.filter(x => x.status == NavigationStatus.NotStarted).length;
        const completedCount = children.filter(x => x.status == NavigationStatus.Complete).length; 
        if (children.length == notStartedCount)
            status = NavigationStatus.NotStarted;
        else if (children.length == completedCount)
            status = NavigationStatus.Complete;
        else
            status = NavigationStatus.Started;
    }
    return status;
}

const MainNavbar = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { jAction } = useJQuery();
    const [open, setOpen] = useState(window.outerWidth > 600);// && !match?.url);
    const [ready, setReady] = useState(false);
    const [shouldShowNavigation, setShouldShowNavigation] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const [logoutText, setLogoutText] = useState('');
    const [signinText, setSigninText] = useState('');
    const [logoutUrl, setLogoutUrl] = useState('');
    const [signinUrl, setSigninUrl] = useState('');
    const [recommendType, setRecommendType] = useState('');
    const [languages, setLanguages] = useState<ILanguage[]>([]);
    const [navigation, setNavigation] = useState<INavigation[]>([]);
    const [pageStatuses, setPageStatuses] = useState<{ [index: string]: { status: NavigationStatus } }>();
    const [userId, setUserId] = useState<string>();
    const [candidateId, setCandidateId] = useState<string>();
    const [navError, setNavError] = useState<string>();
    const [portalHeader, setPortalHeader] = useState<string>();
    const [churchHeader, setChurchHeader] = useState<string>();
    const [user, setUser] = useState<{ fullName: string, id: string }>();
    const anchorEl = 'nav-anchor';
    const profileRef = useRef();
    const languagesRef = useRef();

    useEffect(() => {
        jAction(() => {
            jQuery.fn.extend({
                shadowRoot: function () {
                    return $(this.get(0).shadowRoot);
                },
            } as any);
            $('platform-footer').css('display', 'block');
            $('platform-header').css('display', 'none'); // hide header completely
            $('platform-header').css('position', 'fixed');
            $('platform-header').css('width', '100%');

            $('.page-copy .xrm-attribute-value').get().forEach((v, i) => {
                $(v).wrapInner(`<label class="field-label" id="intro-text-${i}"></label>`);
            });
            const fullName = $('#user_name').val() as string;
            const id = $('#user_id').val() as string;
            const cId = $('#candidate_id').val() as string;
            const hideNav = $('#hide_nav').val() as string;
            const header = $('#portal_header').val() as string;
            const church_header = $('#church_header').val() as string;
            setUserId(id);
            setCandidateId(cId);
            setPortalHeader(header || 'Missionary Portal');
            setChurchHeader(church_header || 'Missionary Portal');
            if (id && !hideNav) {
                setUser({ fullName, id });
                dispatch(SetUser({ fullName, id }));
                if (window.outerWidth > 600) { // && !match?.url) {
                    handleDrawerOpen();
                }
            } else {
                handleDrawerClose();
            }

            const langCode = $('#lang_code').val() as string;
            const languages = $('#languages-dropdown ul.dropdown-menu > li').toArray().map(l => {
                const language = $(l).find('a[data-url]');
                const url = language.attr('data-url') as string;
                const code = language.attr('data-lang-code') as string;
                const selected = code && langCode && code.toLowerCase() === langCode.toLowerCase();
                const name = language.text();
                return {
                    name,
                    url,
                    code,
                    selected
                } as ILanguage;
            });

            setLanguages(languages);
        });

        document.body.addEventListener('set-loading', handleDrawerClose);

        return function cleanup() {
            document.body.removeEventListener('set-loading', handleDrawerClose);
        }
    }, []);

    useEffect(() => {
        if (!candidateId) {
            setReady(true);
            setPageStatuses({});
            return;
        };
        const errorCallback = (err?: any) => {
            console.error('unable to retrieve sitemap: ' + err);
            jAction(() => setNavError($('#nav_error').val() as string ?? 'error'));
            setPageStatuses({});
        }
        const getPageStatuses = async () => {
            const navTimeout = setTimeout(() => errorCallback('(timeout)'), 5000); // in case the function takes forever
            await jAction(async () => {
                const functionUrl = $('#nav_function_url').val();
                try {
                    const response = await fetch(`${functionUrl}&UserId=${candidateId}`, {
                        method: 'GET'
                    });
                    const result = await response.json() as any;
                    setPageStatuses(result);
                    setNavError('');
                } catch (e) {
                    errorCallback(e);
                } finally {
                    setReady(true);
                    clearTimeout(navTimeout);
                }
            });
        };
        getPageStatuses();
    }, [candidateId]);

    useEffect(() => {
        if (!ready && !pageStatuses) return;
        jAction(() => {
            const logoutText = $('#logout_text').val() as string;
            const signinText = $('#signin_text').val() as string;
            const logoutUrl = $('#logout_url').val() as string;
            const signinUrl = $('#signin_url').val() as string;
            const langCode = $('#lang_code').val() as string;
            const recType = $('#recommend_label').val() as string;
            const candidateId = $('#candidate_id').val() as string;
            const recommendId = $('#recommend_id').val() as string;
            const agreementId = $('#agreement_id').val() as string;
            const medicalHistoryId = $('#medical_history_id').val() as string;
            const mentalHealthId = $('#mental_health_id').val() as string;

            setLogoutText(logoutText ?? 'Sign Out');
            setSigninText(signinText ?? 'Sign In');
            setLogoutUrl(logoutUrl);
            setSigninUrl(signinUrl);
            setRecommendType(recType);

            // ==========> Handle Nav Links
            const shouldShowNavValue = ($('#should_show_nav')?.val() as string) == 'false' ? false : true;
            setShouldShowNavigation(shouldShowNavValue);

            const linkIds = ($('#link_ids').val() as string)?.split(';') || [];
            const linkNames = ($('#link_names').val() as string)?.split(';') || [];
            const linkUrls = ($('#link_urls').val() as string)?.split(';') || [];
            const hiddenStatusIds = ($('#hidden_status_ids').val() as string)?.split(',').filter(v => !!v) || [];
            const appendIds = `?candidateId=${candidateId}&recommendId=${recommendId}&medicalHistoryId=${medicalHistoryId}&agreementId=${agreementId}&mentalHealthId=${mentalHealthId}`;
            try {
                const links: INavigation[] = linkNames.map((n, i) => {
                    try {
                        const childNameClean = n.split(' ').join('').replace(specialCharRegex, '_');
                        const childIds = ($(`#${childNameClean}_link_ids`).val() as string)?.split(';') || [];
                        const childNames = ($(`#${childNameClean}_link_names`).val() as string)?.split(';') || [];
                        const childUrls = ($(`#${childNameClean}_link_urls`).val() as string)?.split(';') || [];
                        const openInNewWindow = ($(`#${childNameClean}_open_new_window`).val() as string)?.toLowerCase() === 'true';
                        const children: INavigation[] = childNames.filter(nj => !!nj).map((nj, j) => {
                            const subchildIds = ($(`#${nj.split(' ').join('').replace(specialCharRegex, '_')}_sublink_ids`).val() as string)?.split(';') || [];
                            const subchildNames = ($(`#${nj.split(' ').join('').replace(specialCharRegex, '_')}_sublink_names`).val() as string)?.split(';') || [];
                            const subchildUrls = ($(`#${nj.split(' ').join('').replace(specialCharRegex, '_')}_sublink_urls`).val() as string)?.split(';') || [];
                            const subchildren: INavigation[] = subchildNames.filter(nk => !!nk).map((nk, k) => ({
                                name: nk,
                                url: `/${langCode}${subchildUrls[k]}${appendIds}`,
                                status: getPageStatus(subchildUrls[k], [], pageStatuses),
                                openNew: openInNewWindow,
                                statusHidden: hiddenStatusIds.some(id => id === subchildIds[k])
                            }));
                            return {
                                name: nj,
                                url: `/${langCode}${childUrls[j]}${appendIds}`,
                                children: subchildren,
                                status: getPageStatus(childUrls[j], subchildren, pageStatuses),
                                openNew: openInNewWindow,
                                statusHidden: hiddenStatusIds.some(id => id === childIds[j])
                            }
                        });
                        return {
                            name: n,
                            url: linkUrls[i] + appendIds,
                            children,
                            status: getPageStatus(linkUrls[i], children, pageStatuses),
                            openNew: openInNewWindow,
                            statusHidden: hiddenStatusIds.some(id => id === linkIds[i])
                        }
                    } catch (e) {
                        console.warn(`error mapping ${n} link names!`);
                        console.error(e);
                        throw e;
                    }
                });
                setNavigation(links);
                if (links.length == 0) {
                    setShouldShowNavigation(false);
                }
            } catch (e) {
                console.warn(e);
            }
        })
    }, [pageStatuses, ready]);

    useEffect(() => {
        jAction(() => {
            let content = $('#liquid_form').length ?
                $('#liquid_form') :
                $('#content-container').length ?
                    $('#content-container') :
                    $('#content_form');
            if (!content.length) {
                content = $('.container');
            }
            if (content.length) {
                // const contentMargin = Number(content.css('margin-left') || 0);
                if (open) {
                    // content.addClass(classes.contentShift).css('transform', `translateX(${drawerWidth}px)`);
                } else {
                    content.removeClass(classes.contentShift).css('transform', 'none');
                }
            }
        });
    }, [open]);

    const changeLanguage = (languageCode: string, url: string) => {
        const ev = new Event('set-loading');
        document.body.dispatchEvent(ev);
        console.log(`switching language: ${url}`);
        webapi.safeAjax({
            type: 'PATCH',
            url: `/_api/contacts(${userId})`,
            contentType: 'application/json',
            data: JSON.stringify({
                'mrs_preferredlanguagecode': languageCode
            }),
            success: function (res) {
                console.log(res);
                window.location.href = '/' + url;
            }
        });
    }

    const handleDrawerOpen = () => {
        $('#root').addClass('nav-open');
        $('platform-footer').addClass('nav-open');
        setOpen(true);
    };

    const handleDrawerClose = () => {
        $('#root').removeClass('nav-open');
        $('platform-footer').removeClass('nav-open');
        setOpen(false);
    };

    const topLevelNav = useMemo(() => {
        const getTopLevel = (child: INavigation): INavigation | null => {
            if (child.url === location.pathname) return child;
            return child.children?.find(c => getTopLevel(c)) || null;
        }
        let nav = '';
        // Find first match
        navigation.forEach(n => {
            const found = getTopLevel(n);
            if (found) {
                nav = found.name;
                return;
            }
        });
        return nav;
    }, [navigation]);
    const getIcon = (nav: INavigation) => {
        if (nav.statusHidden) {
            return <Minus size={iconSize} className={classes.empty} />
        }
        switch (nav.status) {
            case NavigationStatus.Complete:
                return <CircleSelectedFilled size={iconSize} className={classes.checkMark} />;
            case NavigationStatus.Started:
                return <CircleStarted size={iconSize} />;
            case NavigationStatus.NotStarted:
            default:
                return <CircleNotStarted size={iconSize} />
        }
    }
    const getIsPartOfPath = (child: INavigation) => {
        try {
            if (new URL(location.origin + child.url).pathname === location.pathname) return true;
            return child.children?.some(c => getIsPartOfPath(c)) || false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
    const mappedNavigation = useMemo(() => {
        const currNav = navigation;
        const getNav = (nav: INavigation) => {
            const partOfPath = getIsPartOfPath(nav);
            return <NavListItem
                icon={getIcon(nav)}
                selected={nav.url === location.pathname}
                text={nav.name}
                partOfPath={partOfPath}
                route={nav.url}
                openNew={nav.openNew}
                key={nav.url}>
                {nav.children?.length ?
                    <>
                        {nav.children.map(n => getNav(n))}
                    </> :
                    undefined
                }
            </NavListItem>
        }
        return currNav.map(n => getNav(n)); // Recursively add nested nav items
    }, [navigation, open]);

    const showDrawer = true; // user?.id && ready && mappedNavigation?.length;

    return (<>
        <div className={clsx(classes.wordMark, {
            [classes.wordMarkShift]: open && showDrawer
        })}>
            <a id="mainLogoLink" href="https://stage.churchofjesuschrist.org?lang=eng" title="The Church of Jesus Christ of Latter-day Saints">
                <div className={classes.slimLogo}>{churchHeader}</div>
            </a>
        </div>
        <WorkforceHeader
            id="wf-header"
            className={classes.workforceHeaderThemed}
            name={<a href="/">{portalHeader}</a>}
            menuButton={!open && showDrawer ? <MenuButton aria-label="MRS Menu" onClick={handleDrawerOpen} /> : <></>}
            tools={<>
                <Icon
                    onClick={() => setLanguageMenuOpen(true)}
                    ref={languagesRef}
                    className={classes.headerIcon}>
                    <World />
                </Icon>
                <span className={classes.spacer}></span>
                <Icon
                    onClick={() => setMenuOpen(true)}
                    ref={profileRef}
                    className={classes.headerIcon}
                >
                    <Profile />
                </Icon>
                <Callout
                    arrow="none"
                    forRef={profileRef}
                    open={menuOpen}
                    onClickOutside={() => setMenuOpen(false)}
                >
                    <Box p={2}>
                        <Unstyled className={classes.unstyledList}>
                            <Item className={classes.menuItem}>
                                <Row align="space-between" columnGapSize="8" rowGapSize="16" verticalAlign="center">
                                    <H4 className="menuHeader">{user?.fullName}</H4>
                                    <A11y href="https://account-stage.churchofjesuschrist.org/personalInformation"><GearOutline /></A11y>
                                </Row>
                                <hr />
                            </Item>
                            <Item className={classes.menuItem}>
                                {user?.id ?
                                    <Secondary fullWidth onClick={() => location.href = logoutUrl}>{logoutText}</Secondary> :
                                    <Secondary fullWidth onClick={() => location.href = signinUrl}>{signinText}</Secondary>}
                            </Item>
                        </Unstyled>
                    </Box>
                </Callout>
                <Callout
                    arrow="none"
                    forRef={languagesRef}
                    open={languageMenuOpen}
                    onClickOutside={() => setLanguageMenuOpen(false)}
                >
                    <Box p={2}>
                        <Unstyled className={classes.unstyledList}>
                            <Item className={classes.menuItem}>
                                <div>
                                    <H4 className="menuHeader"><Language /></H4>
                                </div>
                                <hr />
                            </Item>
                            {languages.map(l =>
                                <Item className={classes.menuItem} key={l.name}>
                                    <A11y className="language-item" onClick={() => changeLanguage(l.code, l.url)}>{l.name} {l.selected && <span className="language-selected-ind">✓</span>}</A11y>
                                </Item>
                            )}
                        </Unstyled>
                    </Box>
                </Callout>
            </>}
        />

        {shouldShowNavigation && <div id={anchorEl} className={classes.root}>
            <CssBaseline />
            {showDrawer &&
                <Drawer
                    className={clsx(classes.drawer, {
                        [classes.drawerClosed]: !open
                    })}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <div className={classes.drawerSubheader}>
                        <Typography variant="h5"><b>{topLevelNav}</b></Typography>
                        <Typography variant="h6">{recommendType}</Typography>
                    </div>
                    {ready ?
                        (user?.id ?
                            <List>
                                {mappedNavigation}
                            </List> :
                            <Secondary style={{ margin: 'auto' }} onClick={() => location.href = signinUrl}>{signinText}</Secondary>
                        ) :
                        <CircularProgress size={50} style={{ margin: 'auto' }} />}
                    {navError && <Alert severity="error">{navError}</Alert>}
                </Drawer>
            }
        </div>}
    </>);
}

export default MainNavbar;

declare const webapi;
